<template>
    <div class="culture">
                <Top v-if="columnList.img_info_list" :img="'/gsmm/api/v1/'+columnList.img_info_list[0].url" :ch="ch" />

		<Title :ch="ch" :en="en" />
		<div class="content">
			{{columnList.description}}
		</div>
       <div class="center">
          <Menu :menuList="columnList.son_tree_column" class="menu" :padding="20" :width="100" />
		  <router-view/>
     </div>

    </div>
</template>

<script>
import Top from '../components/top.vue'
import Menu from '../components/menu.vue'
import Title from '../components/title.vue'
import { mapActions, mapState } from 'vuex'
export default {
    data() {
        return {
            ch:'成功案例',
			en:'SUCCESFUL CASES',

        };
    },
    components:{
        Top,
        Menu,
		Title
    },
	computed:{
...mapState(['columnList'])
	},     
    created() {

    },
    mounted() {
		this.handleColumn(16)
    },
    methods: {
		...mapActions(['handleColumn'])
    }
};
</script>

<style scoped lang='less'>
.culture{
    width:100%;
	background-image: url('../../assets/bg.png');
	padding-bottom: 50px;
}
.content{
	padding: 20px 30%;
}
.center{
    padding:0 20%;
	.img{
		margin-top: 50px;
		padding:0 20%;
		img{
			width:100%
		}
	}
	.el-carousel{
		text-align: center;
		/* width: 1000px; */
		margin-top: 50px;
	}
}
</style>
